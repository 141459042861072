@use "../abstracts" as *;

.c-top {
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  padding: 0;
  border: none;
  border-radius: var(--br);
  background-color: hsl(9, 97%, 14%);
  color: var(--clr-neutral-100);

  &:hover,
  &:focus {
    background-color: hsl(9, 97%, 17%);
  }

  &:active {
    background-color: hsl(9, 97%, 20%);
  }

  @include pseudo($full: false, $left: 50%, $top: 50%) {
    width: 60%;
    height: 60%;
    transform: translate3d(-50%, -50%, 0);
    background: url("../img/top-icon.svg") no-repeat center / contain;
  }
}
