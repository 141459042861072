@use "../abstracts" as *;

.s-contact {
  overflow: hidden;

  .container--custom {
    position: relative;
  }

  &__img {
    display: block;
    width: rem(575);
    position: absolute;
    top: 0;
    right: 0;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    @include pseudo() {
      display: none;
      margin-right: -1px;
      background: linear-gradient(to left, #fff 0%, transparent 5%);
    }
  }

  &__icons {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;

    > li {
      display: flex;
      justify-content: flex-start;

      > a {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 700;
        color: inherit;

        &:hover {
          text-decoration: none;

          color: var(--clr-neutral-30);

          .s-contact {
            &__icon {
              background-color: var(--clr-primary-active);
            }
          }
        }
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    display: grid;
    place-items: center;
    width: 2.75rem;
    aspect-ratio: var(--hexagon-proportion);
    background-color: var(--clr-primary);
    mask: url("../img/hexagon-shape.svg") no-repeat center / contain;

    img {
      width: 1.5rem;
      height: 1.5rem;
      object-fit: contain;
      pointer-events: none;
      user-select: none;
    }
  }

  @include breakpoint-up($breakpoint-container) {
    &__img {
      &::after {
        display: block;
      }
    }
  }

  @include breakpoint(xl) {
    &__img {
      display: none;
    }
  }
}
