@use "../abstracts" as *;

:root {
  --clr-primary-hue: 354;
  --clr-primary-saturation: 96%;
  --clr-primary-light: 44%;

  --clr-primary: hsl(354, 96%, 44%);
  --clr-primary-hover: hsl(354, 96%, 41%);
  --clr-primary-active: hsl(354, 96%, 38%);

  --clr-primary-dark: hsl(349, 87%, 38%);

  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --clr-#{$color}-#{$shade}: #{$value};
    }
  }
}
