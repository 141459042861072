@use "../abstracts" as *;

.c-video {
  position: relative;
  max-width: rem(720);

  &__video {
    display: block;
    width: 100%;
    max-height: rem(768);
  }

  &__btn {
    display: grid;
    place-items: center;
    width: rem(100);
    height: rem(100);
    border: 4px solid var(--clr-neutral-100);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: transparent;

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:active {
      background-color: rgba(71, 71, 71, 0.25);
    }

    i {
      color: var(--clr-neutral-100);
      font-size: 3rem;
    }
  }

  @include breakpoint(xxl) {
    &__btn {
      width: 4rem;
      height: 4rem;
      border-width: 2px;

      i {
        color: var(--clr-neutral-100);
        font-size: 1.5rem;
      }
    }
  }
}
