@use "../abstracts" as *;

.form-group {
  margin-bottom: rem(10);
}

.form-control {
  border-radius: 0;
  border-color: #95928a;
  color: #0d0b0d;

  &:focus {
    border-color: hsl(0 0% 10% / 25%);
    box-shadow: 0 0 0 0.2rem hsl(0 0% 10% / 25%);
    color: #0d0b0d;
  }
}
