@use "../abstracts" as *;

.container {
  &--custom {
    width: 100%;
    max-width: $container-width;
    margin: 0 auto;
    padding: 0 1rem;
  }
}
