@use "../abstracts" as *;

:root {
  --clr-primary-gradient: hsl(351, 100%, 44%);
  --clr-primary-dark-gradient: hsl(355, 70%, 32%);

  --linear-gradient: linear-gradient(
    to right,
    var(--clr-primary-gradient) 40%,
    var(--clr-primary-dark-gradient) 100%
  );
  --radial-gradient: radial-gradient(var(--clr-primary-gradient), var(--clr-primary-dark-gradient));

  @include breakpoint(xl) {
    --linear-gradient: linear-gradient(
      22.5deg,
      var(--clr-primary-gradient) 20%,
      var(--clr-primary-dark-gradient) 75%
    );
  }
}
