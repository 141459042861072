@use "../abstracts" as *;

html {
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--navbar-height) - 1px);
}

::selection {
  background-color: var(--clr-primary);
  color: white;
}

img,
svg,
video {
  max-width: 100%;
}
