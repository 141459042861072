@use "../abstracts" as *;

body {
  font-family: "Nexa", sans-serif;
  color: var(--clr-neutral-10);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

h1,
.h1 {
  font-size: rem(52);
}

h2,
.h2 {
  font-size: rem(44);
}

h3,
.h3 {
  font-size: rem(38);
}

h4,
.h4 {
  font-size: rem(22.65);
}

h5,
.h5 {
  font-size: rem(20);
}

p,
.p {
  display: block;
  margin: 0;
  font-size: rem(18);
  line-height: 1.5;
}

.form-group,
.form-control {
  font-family: "Roboto", sans-serif;
}

.form-control {
  font-weight: 100;
  font-size: rem(18);
}

@include breakpoint(sm) {
  h1,
  .h1 {
    font-size: rem(48.832);
  }

  h2,
  .h2 {
    font-size: rem(39.056);
  }

  h3,
  .h3 {
    font-size: rem(31.248);
  }

  h4,
  .h4 {
    font-size: rem(25.008);
  }

  h5,
  .h5 {
    font-size: rem(18);
  }

  p,
  .p {
    font-size: rem(16);
    line-height: 1.5;
  }

  .form-control {
    font-size: rem(16);
  }
}
