@use "../abstracts" as *;

.swiper {
  &__navigation {
    display: block;
    position: absolute;
    top: 50%;
    width: rem(32);
    height: rem(32);
    z-index: 2;

    @include pseudo() {
      mask: no-repeat center / contain;
      background-color: var(--clr-neutral-87);
    }

    &--disabled {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__prev {
    left: 0;
    transform: translate3d(0, -50%, 0);

    &::after {
      mask-image: url("../img/icon-prev.svg");
    }
  }

  &__next {
    right: 0;
    transform: translate3d(0, -50%, 0);

    &::after {
      mask-image: url("../img/icon-next.svg");
    }
  }
}
