@use "../abstracts" as *;

.s-services {
  --card-dimension: #{rem(200)};

  &__paragraph {
    max-width: rem(390);
  }

  &__swiper {
    display: none;

    .hexagon {
      margin: 0 auto;
    }
  }

  &__cards,
  &__cols {
    --space: 0.5rem;

    list-style: none;
    display: flex;
    gap: var(--space);
    padding: 0;
  }

  &__cards {
    flex-direction: column;
    padding: 0 calc(var(--card-dimension) * 0.5);
  }

  &__row {
    &:where(:nth-child(2n)) {
      margin-left: calc((var(--card-dimension) * -0.5) - (var(--space) * 0.5));
    }

    &:where(:not(:first-child)) {
      margin-top: rem(-45);
    }
  }

  .hexagon {
    width: var(--card-dimension);
  }

  @include breakpoint(xl) {
    &__paragraph {
      max-width: none;
    }

    &__swiper {
      display: block;
    }

    &__cards {
      display: none;
    }
  }
}
