@use "../abstracts" as *;

.s-about {
  background: var(--linear-gradient);
  color: var(--clr-neutral-100);

  ::selection {
    background-color: white;
    color: var(--clr-primary);
  }

  .container--custom {
    min-height: rem(750);
    position: relative;
    padding-top: 3rem;
    padding-right: 6rem;
    padding-bottom: #{rem(150)};
  }

  &__img {
    display: block;
    width: rem(760);
    position: absolute;
    top: 0;
    left: 0;
    user-select: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
    }

    @include pseudo() {
      display: none;
      background: linear-gradient(to right, var(--clr-primary-gradient) 0%, transparent 5%);
      margin-left: -1px;
    }
  }

  .row {
    width: 100%;
  }

  .col {
    max-width: rem(550);
  }

  p {
    hyphens: auto;
    font-size: rem(20);
  }

  @include breakpoint-up($breakpoint-container) {
    &__img {
      &::after {
        display: block;
      }
    }
  }

  @include breakpoint(xl) {
    .container--custom {
      min-height: 0;
      padding: 3rem 1rem;
      background: none;
    }

    &__img {
      display: none;
    }

    .row {
      margin: 0;
    }

    .col {
      max-width: none;
    }
  }
}
