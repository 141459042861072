@use "../abstracts" as *;

.hexagon {
  cursor: pointer;
  display: grid;
  place-items: center;
  width: rem(200);
  aspect-ratio: 1 / 1.123;
  padding: 2rem 1rem;
  position: relative;

  @include pseudo() {
    z-index: -1;
    mask: url("../img/hexagon-shape-outline.svg") no-repeat center / contain;
    background-color: var(--clr-neutral-68);
  }

  &__icon {
    display: block;
    width: rem(50);
    height: rem(50);
    margin: 0 auto;
    fill: var(--clr-neutral-68);
  }

  &__txt {
    display: block;
    max-width: rem(125);
    margin-top: 0.5rem;
    font-size: rem(18);
    text-align: center;
    line-height: 1.2;
    color: #808080;
  }

  &:hover {
    &::after {
      background-color: var(--clr-primary-dark);
    }

    .hexagon {
      &__icon {
        fill: var(--clr-primary-dark);
      }

      &__txt {
        color: #333333;
      }
    }
  }

  &--empty {
    pointer-events: none;
    opacity: 0.75;
  }
}
