@use "../abstracts" as *;

.l-header {
  transition: background-color 0.2s;

  .container--custom {
    padding: 0.5rem 1rem;
  }

  @include pseudo() {
    box-shadow: 0 0.2rem 0.25rem rgba(25, 25, 25, 0.15);
    opacity: 0;
    z-index: -1;
  }

  &--scrolling {
    background-color: var(--clr-neutral-100);

    &::after {
      opacity: 1;
    }

    .container--custom {
      padding: 0.25rem 1rem;
    }

    .navbar {
      &-brand {
        max-width: rem(200);
      }
    }
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--clr-neutral-100);
}

.navbar-light .navbar-nav .nav-link {
  color: var(--clr-neutral-0);
}

.navbar {
  &-brand {
    max-width: rem(250);
    padding: 0;
    position: absolute;
    top: 0;
    left: 1rem;
    transition: all 0.4s;
  }

  &-nav {
    margin-right: -0.5rem;
    gap: 0 1rem;
  }
}

.nav {
  &-link {
    text-transform: uppercase;
  }

  &-icon {
    width: rem(48);
    height: rem(48);
    object-fit: contain;
  }
}

@include breakpoint(xl) {
  .l-header {
    &--scrolling {
      .container--custom {
        padding: 0.5rem 1rem;
      }

      .navbar {
        &-brand {
          max-width: rem(150);
        }
      }
    }
  }

  .navbar {
    &-brand {
      max-width: rem(150);
    }

    &-nav {
      margin: 0;
    }
  }
}
