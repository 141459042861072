@use "../abstracts" as *;

.l-footer {
  background: url("../img/footer-bg.svg") no-repeat center / cover, var(--radial-gradient);
  background-blend-mode: overlay;
  color: var(--clr-neutral-100);

  ::selection {
    background-color: white;
    color: var(--clr-primary);
  }

  hr {
    opacity: 0.75;
    border-color: var(--clr-neutral-57);
    border-width: calc(#{rem(0.75)} * 0.5);
  }

  &__brand {
    max-width: rem(325);
  }

  .c-top {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate3d(0, -50%, 0);
  }

  @include breakpoint(md) {
    &__brand {
      max-width: rem(225);
    }
  }

  @include breakpoint(sm) {
    .c-top {
      display: block;
      margin: 1rem auto 0;
      position: relative;
      top: 0;
      transform: none;
    }
  }
}
