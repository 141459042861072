@use "../abstracts" as *;

.s-success-stories {
  .container--custom {
    --sx: 5rem;

    padding: 0 var(--sx);
    position: relative;

    .swiper {
      &__prev {
        left: calc(var(--sx) * 0.5);
        transform: translate3d(-50%, -50%, 0);
      }

      &__next {
        right: calc(var(--sx) * 0.5);
        transform: translate3d(50%, -50%, 0);
      }
    }
  }

  @include breakpoint(sm) {
    .container--custom {
      --sx: 1rem;

      .swiper {
        &__prev,
        &__next {
          top: 60%;
        }

        &__prev {
          left: 0;
          transform: translate3d(0, -50%, 0);
        }

        &__next {
          right: 0;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
  }
}
