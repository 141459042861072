@use "../abstracts" as *;

:root {
  --navbar-height: #{rem(72)};

  --hexagon-proportion: 1 / 1.128;

  @include breakpoint(xl) {
    --navbar-height: #{rem(56)};
  }
}
