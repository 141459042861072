@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Brie Light";
  font-style: normal;
  font-weight: normal;
  src: local("Brie Light"), url("../fonts/BRI293.woff") format("woff");
}
