@use "../abstracts" as *;

.card {
  border: none;
  text-align: center;

  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &-img {
    aspect-ratio: var(--hexagon-proportion);
    max-width: rem(275);
    margin: 0 auto;
    position: relative;
    border-radius: 0;
    mask: url("../img/hexagon-shape.svg") no-repeat center / contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include pseudo() {
      background: var(--radial-gradient);
      opacity: 0.5;
      transition: opacity 0.4s;
    }
  }

  &-list {
    list-style: none;
    padding: 0;
  }

  &:hover {
    .card {
      &-img {
        &::after {
          opacity: 0;
        }
      }
    }
  }
}
