.c-social {
  list-style: none;
  display: flex;
  gap: 1rem;
  padding: 0;

  a {
    display: grid;
    place-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: var(--br);
    background-color: var(--clr-neutral-0);
    color: var(--clr-neutral-100);

    &:hover,
    &:focus {
      background-color: var(--clr-neutral-10);
      text-decoration: none;
    }
  }
}
