@use "../abstracts" as *;

.s-hero {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: rem(56) 0;
  background: url("../img/hero-bg.webp") no-repeat center / cover;
  color: var(--clr-neutral-100);

  @include breakpoint(md) {
    h1 {
      br {
        display: none;
      }
    }
  }
}
