@use "../abstracts" as *;

.s-products {
  --link-dimension: #{rem(150)};
  --links-spacing: 1rem;

  min-height: rem(755);
  background: var(--linear-gradient);

  h2,
  .h2 {
    font-size: rem(32);
  }

  .container--custom {
    position: relative;
    padding: 3rem 1rem;

    &::before,
    &::after {
      content: "";
      display: block;
      width: rem(1200);
      aspect-ratio: var(--hexagon-proportion);
      position: absolute;
      mask: url("../img/hexagon-shape.svg") no-repeat center / contain;
      background-color: var(--clr-neutral-87);
    }

    &::before {
      top: 0;
      left: 0;
      transform: translate3d(-28%, -61%, 0);
    }

    &::after {
      bottom: 0;
      right: 0;
      transform: translate3d(20%, 61%, 0);
    }
  }

  &__txt-wrapper,
  &__links {
    z-index: 1;
  }

  &__txt-wrapper {
    position: absolute;
    top: 3rem;
    left: 3rem;
    max-width: rem(575);

    p {
      max-width: rem(445);
      margin: 0 auto;
    }
  }

  &__swiper {
    display: none;

    .swiper {
      &-slide {
        > div {
          display: flex;
          justify-content: center;
        }
      }
    }

    .s-products {
      &__link {
        justify-content: center;
      }
    }
  }

  .swiper {
    &__navigation {
      &::after {
        background-color: var(--clr-neutral-20);
      }
    }
  }

  &__links {
    padding-left: calc((var(--link-dimension) * 0.5) + (var(--links-spacing) * 0.5) + 1.25rem);
    position: absolute;
    right: 2rem;
    bottom: 1rem;
  }

  &__row {
    display: flex;
    gap: var(--links-spacing);

    &:where(:nth-child(2n)) {
      margin-left: calc((var(--link-dimension) * -0.5) - (var(--links-spacing) * 0.5));
    }

    &:where(:not(:first-child)) {
      margin-top: -1.5rem;
    }
  }

  &__link {
    a {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;

        .s-products {
          &__label {
            opacity: 1;
          }
        }
      }
    }

    &--highlight {
      position: relative;
      z-index: 1;
      transform: scale(1.25);
      filter: drop-shadow(0 0 0.5rem rgba(25, 25, 25, 0.35));
    }
  }

  &__img {
    width: var(--link-dimension);
    aspect-ratio: var(--hexagon-proportion);
    mask: url("../img/hexagon-shape.svg") no-repeat center / contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__label {
    font-family: "Brie Light";
    font-size: rem(44);
    font-weight: 400;
    line-height: 1;
    opacity: 0;
  }

  @include breakpoint(xxl) {
    --link-dimension: #{rem(125)};

    min-height: rem(675);

    .container--custom {
      &::before,
      &::after {
        width: rem(1125);
      }

      &::before {
        transform: translate3d(-22%, -62%, 0);
      }

      &::after {
        transform: translate3d(20%, 62%, 0);
      }
    }

    &__label {
      font-size: rem(38);
    }
  }

  @include breakpoint(lg) {
    min-height: auto;
    background: var(--clr-neutral-87);

    .container--custom {
      &::before,
      &::after {
        display: none;
      }
    }

    &__txt-wrapper,
    &__links {
      display: block;
      position: relative;
      inset: 0;
      margin: 0 auto;
    }

    &__txt-wrapper {
      margin-bottom: 2rem;
    }

    &__swiper {
      display: block;
    }

    &__links {
      display: none;
    }
  }
}
