@use "../abstracts" as *;

.btn {
  padding: 1em 2.25em;
  border-radius: 0;
  font-size: rem(18);
  text-transform: uppercase;
  line-height: 1;

  &--primary {
    background-color: var(--clr-primary);
    color: var(--clr-neutral-100);

    &:hover,
    &:focus {
      background-color: var(--clr-primary-hover);
      color: var(--clr-neutral-100);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem
        hsl(var(--clr-primary-hue) var(--clr-primary-saturation) var(--clr-primary-light) / 25%);
    }

    &:active {
      background-color: var(--clr-primary-active);
    }
  }

  &--light {
    background-color: var(--clr-neutral-100);
    color: var(--clr-primary);

    &:hover,
    &:focus {
      background-color: var(--clr-neutral-95);
      color: var(--clr-primary);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
    }

    &:active {
      background-color: var(--clr-neutral-90);
    }
  }

  &--gray {
    background-color: hsl(260, 1%, 47%);
    color: var(--clr-neutral-100);

    &:hover,
    &:focus {
      background-color: hsl(260, 1%, 42%);
      color: var(--clr-neutral-100);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem hsl(260, 1%, 37%, 0.25);
    }

    &:active {
      background-color: hsl(260, 1%, 37%);
    }
  }
}
