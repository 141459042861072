@use "../abstracts" as *;

.l-whatsapp {
  display: grid;
  place-items: center;
  width: rem(60);
  height: rem(60);
  position: fixed;
  right: 2.75rem;
  bottom: 2.75rem;
  z-index: 5;
  border-radius: 50%;
  background-color: hsl(152, 97%, 31%);
  transition: background-color 0.4s;
  user-select: none;

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background-color: hsl(152, 97%, 28%);
  }

  &:active {
    background-color: hsl(152, 97%, 25%);
  }

  @include breakpoint(sm) {
    right: 1rem;
    bottom: 1rem;
  }
}
